import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TeamMemberView from "../components/TeamMemberView"
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { IPageData } from "../model/QueryData";
import { TeamMember } from "../model/TeamMember";

export default function About(props: IPageData) {
  const { data } = props;
  const { t } = useTranslation();
  const team = data.allMarkdownRemark!.edges;

  return (
    <Layout>
    <>
      <section className="section">
        <header>
          <h1 className="title is-2 has-text-centered mainTitle">{t ('about')}</h1>
        </header>
        <div className="container containerAbout">
          <article className="is-flex columns is-align-items-center">
            <div className="column">
              <h1 className="title is-3 aboutSubTitle">{t('mission')}</h1>
              <p className="is-size-5">{t('missionText')}</p>
            </div>
            <div className="column is-flex is-justify-content-center">
              <StaticImage
                src="../images/boat.svg"
                alt={t('mission')}
              />
            </div>
          </article>
          <article className="is-flex columns is-align-items-center">
            <div className="column">
              <h1 className="title is-3 aboutSubTitle">{t('vision')}</h1>
              <p className="is-size-5">{t('visionText')}</p>
            </div>
            <div className="column is-flex is-justify-content-center">
              <StaticImage
                src="../images/lamp.png"
                alt={t('vision')}
              />
            </div>
          </article>
          <div className="about">&nbsp;</div>
        </div>
        </section>
        <section className="section members">
          <header>
            <h1 className="title is-2 has-text-centered mainTitle">The Team</h1>
          </header>
          <div className="container containerMembers">
          {team.map(edge => (
            <TeamMemberView member={new TeamMember(edge)} />
          ))}
          </div>
        </section>
    </>
    </Layout>
  );
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(filter: {
      frontmatter: {
        job: {ne: null},
        language: {eq: $language}
      }
    }) {
      edges {
        node {
          html
          frontmatter {
            slug
            job
            name
            photo
          }
        }
      }
    }
  }
`
