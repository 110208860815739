import { IMarkdownRemarkEdge } from "./QueryData";

export class TeamMember {
  url: string;
  name: string;
  job: string;
  html: string;
  photo?: string;

  constructor(edge: IMarkdownRemarkEdge) {
    this.url = edge.node.frontmatter!.slug!;
    this.name = edge.node.frontmatter!.name || "?";
    this.job = edge.node.frontmatter!.job || "?";
    this.html = edge.node.html || "no content";
    this.photo = edge.node.frontmatter!.photo || undefined;
  }
}
